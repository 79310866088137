import React from 'react'

export default function Uxtwitch(props) {

    let width = props.width || 800
    let height = props.height || 450
    let fullscreen = props.fullscreen || true

    let url = null
    if(props.channel) {
        url = 'channel=' + props.channel
    }
    else if(props.video) {
        url = 'video=' + props.video
    }
    else if(props.collection) {
        url = 'collection=' + props.collection
    }

    if(props.autoplay) {
        url += "&autoplay=" + props.autoplay
    }

    if(props.muted) {
        url += "&muted=" + props.muted
    }

    if(props.time) {
        url += "&time=" + props.time
    }

    if(props.chat) {
        url = 'https://www.twitch.tv/embed/' + props.chat + '/chat'

        if(props.theme === 'black') {
            url += '?darkpopout'
        }

        if(props.width && !props.height) {
            height = props.width * (563 / 350)
        }
        else if(!props.width && props.height) {
            width = props.height * (350 / 563)
        }
    }
    else {
        url = "https://player.twitch.tv/?" + url

        if(props.width && !props.height) {
            height = props.width * .5625
        }
        else if(!props.width && props.height) {
            width = props.height * 1.7778
        }
    }


    return (
        <iframe
            title="twitch"
            src={ url }
            height={ height }
            width={ width }
            frameBorder="0"
            scrolling="no"
            allowFullScreen={ fullscreen }>
        </iframe>
    )

}