import React from 'react'

import Uxtwitch from './components/ux/Uxtwitch'

export default function App() {

  return (
    <div className="app">
      <div className="twitch-wrapper">
        <Uxtwitch channel="khraine_fr" width="1000" />
        <Uxtwitch chat="khraine_fr" width="350" theme="black" />
      </div>
    </div>
  )
}
